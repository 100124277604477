@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  // font-family: "Poppins" Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.icon {
  color: var(--green);
  margin: 0 10px;
  font-size: 24px;
}
.pages-header {
  background-image: url("./assets/images/bakground-gold.svg");
  background-size: contain;
  height: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 4vmin !important;
    color: var(--green);
    cursor: pointer;
    &.icon-ar {
      scale: -1;
    }
  }
}

.title-page {
  color: var(--green);
  font-weight: 600;
  font-size: 2em;
  width: 60%;
  text-align: center;
}

.pages-position {
  position: absolute;
  top: 72px;
  width: var(--full-width);
}

h1 {
  font-size: 1.5em;
  font-weight: 500;
}

h2 {
  font-size: 1.3em;
  line-height: 2.5em;
  font-weight: 500;
}

h3 {
  font-size: 1.1em;
  line-height: 2.5em;
  font-weight: 500;
}

/*** stepper bar ***/
input[type="range"].bar-stepper {
  width: 80vw;
  background-color: var(--back-gray-2);
  border-radius: 0%;
  height: 10px;

  &::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    background-color: var(--golden);
    box-shadow: -40vw 0 0 40vw var(--golden);
    border-radius: 0%;
  }
  &.arabic-bar {
    background-color: var(--golden);
    &::-webkit-slider-thumb {
      box-shadow: -40vw 0 0 40vw var(--back-gray-2);
      direction: rtl;
    }
  }
}

/************ ScrollBar **************/
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light-green);
  border-radius: 5px;
  min-height: 80px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--green);
}

@media (max-width: 1300px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  h1 {
    font-size: 1.2rem;
  }
}

@media (max-width: 800px) {
  body {
    font-size: 13px;
  }
  .title-page {
    width: 70%;
  }
}
@media (max-width: 500px) {
  body {
    font-size: 12px;
  }
  .title-page {
    font-size: 1.5em;
    width: 80%;
  }
  .pages-header {
    // background-size: cover;
  }
}
