.zakat-calculator-body {
  padding: var(--padding-pages);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 8em - 1rem - 72px);
  position: relative;
  color: var(--black);

  .card-calculator {
    border-radius: var(--border-radius);
    border: 1px solid var(--gray-border);
    &:not(.small-screen) {
      width: 30vw;
      padding: 2em;
      position: absolute;
      right: 4em;
      top: 4em;
    }
    &.small-screen {
      padding: 1em;
      margin-bottom: 1em;
    }
    &.arabic-card {
      right: auto;
      left: 4em;
    }
  }
  h1 {
    color: var(--green);
    font-weight: 700;
  }
  .zakat-possession {
    display: flex;
    align-items: center;
    .possession {
      input,
      span {
        height: 44px;
      }
    }

    img {
      &.icon-possession {
        width: 5em;
        margin-right: 3em;
      }
      &.arab-icon-possession {
        width: 5em;
        margin-left: 3em;
      }
      &.icon-information {
        margin-inline: 1em;
      }
      &.image-money {
        width: 9em;
      }
    }
    h2 {
      min-width: 20em;
    }
    .karat {
      /* to remove the arrows in the input type number*/
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .result-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
    justify-content: center;
    .result-section {
      width: 18em;
      padding: 1.5em;
      font-size: 1.1em;
      border-radius: var(--border-radius);
      margin: 1em;
      background-color: var(--back-gray-3);
      box-shadow: 0 5px 8px -2px var(--back-brown), 0 0 0 1px var(--back-brown);
      h2 {
        font-size: 1.2em;
        color: var(--green);
        text-align: center;
      }
      img {
        height: 3em;
        margin-inline: 2em;
      }
      h3 {
        color: var(--brown);
      }
      .result,
      .result-ar {
        background-color: var(--back-green-3);
        margin-top: 1.5em;
        height: 3em;
        padding: 0.5em;
        border: 0.5px solid var(--green);
        border-radius: var(--border-radius);
        width: 17em;
        h1 {
          font-size: 0.85em;
          font-weight: 500;
          min-width: 5.85em;
        }
        .value-zakat {
          font-size: 0.9em;
        }
      }
      .result {
        transform: translateX(-1em);
      }
      .result-ar {
        transform: translateX(1em);
      }
    }
  }
  .buttons {
    margin-top: 1em;
    .reset-btn,
    .download-btn {
      padding: 0.5em 1.2em;
      font-size: 1.2em;
      font-weight: 500;
      border-radius: var(--border-radius);
      box-shadow: 0 5px 8px -2px var(--back-brown), 0 0 0 1px var(--back-brown);
    }
    .reset-btn {
      color: var(--brown);
      background-color: var(--back-brown);
      border: 1px solid var(--brown);
    }
    .download-btn {
      color: var(--green);
      background-color: var(--back-green-3);
      border: 1px solid var(--green);
    }
  }

  img.icon-information {
    width: 1.5em;
    cursor: pointer;
  }
}

@media (max-width: 900px) {
  .zakat-calculator-body {
    .card-calculator:not(.small-screen) {
      width: 40vw;
    }
  }
}

@media (max-width: 600px) {
  .zakat-calculator-body {
    padding: 1em;
    .card-calculator {
      h3 {
        line-height: 2em;
      }
    }
    .zakat-possession {
      .possession {
        input,
        span {
          height: 40px;
        }
      }

      img {
        &.image-money {
          width: 7em;
          margin-inline: 1em;
        }
        &.icon-possession {
          margin-right: 1em;
        }
      }
      h2 {
        font-size: 1.1em;
        min-width: auto;
      }
    }
  }
}

@media (max-width: 450px) {
  .zakat-calculator-body {
    .zakat-possession {
      .possession {
        input[type="number"] {
          width: 5em;
        }
      }

      img {
        &.icon-possession {
          width: 3.5em;
        }
      }
      &.icon-information {
        margin-inline: 1em;
      }
    }
  }
}
