.audio-player {
  display: flex;
  align-items: center;
  gap: 10px;
  direction: ltr;
  input[type="range"] {
    &::-webkit-slider-thumb {
      width: 13px;
      -webkit-appearance: none;
      appearance: none;
      height: 15px;
      background: var(--green);
      box-shadow: -40vw 0 0 40vw var(--green);
      border-radius: 50%;
    }
  }
  &.withBorder {
    border-radius: var(--border-radius);
    box-shadow: 0rem 0 1rem rgba(0, 0, 0, 0.1);
    border: 1px solid var(--gray-border);
    background-color: rgb(238, 238, 238, 0.5);
    padding: 0.5em 1em;
  }
}
.icon.faCirclePlay {
  font-size: 2em !important;
  color: var(--green);
  &:hover {
    color: var(--green-hover);
  }
}
.icon.faCirclePause {
  font-size: 2em !important;
  color: var(--red);
  &:hover {
    color: var(--red-hover);
  }
}

#seek-bar {
  width: 50vw;
  cursor: pointer;
}

@media (max-width: 670px) {
  #seek-bar {
    width: 41vw;
  }
  .icon {
    &.faCirclePlay,
    &.faCirclePause {
      margin: 0;
    }
  }
}
