.allah-names-header {
  background-image: url("../../assets/images/background-names-allah.png");
  background-repeat: repeat-x;
  background-size: contain;
  height: 41.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: -2;
  top: -9px;
  left: 0px;
  width: 100vw;
  img {
    height: 6em;
    position: absolute;
    top: 4em;
  }
  .audio-allah-names {
    position: absolute;
    top: 13em;
    width: 30em;
  }
}

.allah-names-body {
  position: absolute;
  top: 17em;
  .allah-names-container {
    padding: var(--padding-pages);
    display: flex;
    flex-wrap: wrap;
    gap: 3em;
    justify-content: center;
    direction: rtl;
  }
  .card-name-allah {
    min-width: 18%;
    text-align: center;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.3em;
    font-family: "Amiri" !important;
    background-color: var(--black-golden-names);
    box-sizing: content-box;
    color: var(--black);
    position: relative;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
      background: linear-gradient(
        to bottom right,
        var(--light-green) 0%,
        #c0b485 100%
      );
      z-index: -1;
      border-radius: var(--border-radius);
    }
    .arabic-name {
      font-size: 3em;
    }
    .name-pronunciation {
      font-size: 1.8em;
      color: var(--pronunciation-names);
    }
    .name-meaning {
      font-size: 1.2em;
      span {
        text-align: center;
      }
    }
    img {
      position: absolute;
      right: 1em;
      bottom: 1em;
      width: 2em;
      display: none;
    }
    &:hover {
      background-color: var(--green);
      color: white;
      img {
        display: block;
      }
      .name-pronunciation {
        color: white;
      }
      &::before {
        background: var(--green);
      }
    }
  }
}

@media (max-width: 1534px) {
  .allah-names-body {
    .card-name-allah {
      min-width: 25%;
    }
  }
}

@media (max-width: 1300px) {
  .allah-names-header {
    img {
      // height: 7em;
      // top: 5em;
    }

    .audio-allah-names {
      top: 12em;
    }
  }
  .allah-names-body {
    .allah-names-container {
      gap: 2em;
    }
    .card-name-allah .arabic-name {
      font-size: 2.5em;
    }
  }
}

@media (max-width: 1042px) {
  .allah-names-body {
    gap: 2em;
    .card-name-allah {
      width: 33%;
      .arabic-name {
        line-height: 1.2em;
      }
    }
  }
  .allah-names-header {
    background-size: cover;
    height: 32.5em;
  }
}

@media (max-width: 700px) {
  .allah-names-body {
    top: 15em;
  }
  .allah-names-header {
    img {
      height: 5em;
      top: 3em;
    }
    .audio-allah-names {
      top: 10em;
      width: 23em;
    }
  }
}

@media (max-width: 600px) {
  .allah-names-body {
    top: 14em;
    .card-name-allah {
      width: 72%;
      padding: 1em;
    }
  }
  .allah-names-header {
    img {
      height: 4em;
    }
    .audio-allah-names {
      top: 9em;
      width: 22em;
    }
  }
}
