.prayers-list {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .prayer {
    img {
      width: 8em;
    }
  }
}

@media (max-width: 700px) {
  .prayers-list {
    column-gap: 1em;
    .prayer {
      flex-basis: 24%;
      img {
        width: 7em;
      }
    }
  }
}
