:root {
  --green: #406259;
  --golden: #a3955d;
  --gray: #e9e8e8;
  --gray-border: #bdbdbd;
  --red: #c12f24;
  --red-hover: #d3453a;
  --chatbot-color: #baccc7;

  --back-golden: rgba(216, 217, 196, 25%);
  --back-golden-nav: #d8d9c4;
  --black-golden-names: #eeeeee;
  --pronunciation-names: #9f8e49;
  --light-olive-green: #e4e3df66;

  --back-gray: #f5f5f5;
  --back-gray-2: #eceded;
  --back-gray-3: #f8f8f8;
  --back-transparent-1: rgba(249, 244, 244, 0.8);
  --back-transparent-2: rgba(249, 244, 244, 0.9);

  --back-green: rgb(39 63 56 / 80%);
  --back-green-2: rgba(64, 98, 89, 0.4);
  --back-green-3: rgba(49, 116, 112, 8%);
  --light-green: #688d83;
  --green-calendar: #237761;
  --green-map: #374944;
  --green-hover: #427568;

  --blue-black: #2b3032;
  --black: #1d342e;
  --black-green: #37554d;
  --back-footer: #f3f3f2;

  --brown: #817850;
  --back-brown: rgb(208, 203, 179, 27%);
  --back-2-brown: #e5e2d4;
  --olive-brown: #7c7144;

  --back-salat: #f5f4f2b8;
  --back-dialog: #d8d6ce;
}
