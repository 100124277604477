.mosque-body {
  display: flex;
  padding: var(--padding-pages);
  .mosque-map {
    width: 60%;
    height: calc(100vh - 72px - 4em - var(--heightVideoPages));
    display: flex;
    flex-direction: row;
    .mode-travel {
      img {
        width: 2em;
        margin: 0.5em;
        border-radius: 50px;
        box-shadow: 0px 2px 2px #4b3f1e2e;
      }
    }
  }
  .list-mosques-container {
    width: 40%;
    input[type="search"] {
      border: 1px solid var(--gray-border);
      border-radius: var(--border-radius);
      height: 2.7rem;
      width: 100%;
      padding-inline: 19px;
      outline: none;
      color: var(--green);
      background-image: url("../../assets/icons/search-icon.svg");
      background-repeat: no-repeat;

      // to remove the icon clear form the search input
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
      }
    }
    .mosques {
      width: 95%;
      height: calc(100vh - 72px - 4em - var(--heightVideoPages));
      overflow-y: scroll;
      padding: 0 2em 1em 1em;
    }
  }
  .list-mosques {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-top: 2em;

    .card-mosque {
      box-shadow: var(--shadow-box);
      padding: 1rem;
      border-radius: var(--border-radius);
      display: flex;
      flex-direction: row;
      cursor: pointer;
      &.selected-mosque {
        background-color: var(--green);
        .mosque-name,
        .mosque-distance {
          color: white;
        }
      }
      .mosque-name {
        font-weight: 500;
      }
      img {
        &.image-mosque {
          height: 4.5em;
          width: 4.5em;
          min-width: 4.5em;
          min-height: 4.5em;
          margin: 0 1.5em;
          border-radius: 8px;
        }
        &.position-icon {
          width: 1rem;
          height: 1rem;
        }
      }
      .mosque-distance {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          padding: 0 0.5rem;
        }
      }
    }
  }
}

.header-mosque {
  #video-mosque {
    object-fit: cover;
    width: 100vw;
    height: var(--heightVideoPages);
    max-width: none;
  }
}

@media (max-width: 1300px) {
  .mosque-body {
    .list-mosques {
      .card-mosque {
        img {
          &.image-mosque {
            height: 4.5em;
            width: 4.5em;
            margin: 0 0.8em;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .mosque-body {
    flex-direction: column-reverse;
    .list-mosques-container,
    .list-mosques-container .mosques {
      width: 100%;
      height: fit-content;
      overflow-y: visible;
      padding: 1em 0;
    }
    .mosque-map {
      width: 100%;
      height: calc(100vh - 72px - 9em - var(--heightVideoPages));
      flex-direction: column-reverse;
      .mode-travel {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
