.footer {
  font-weight: 500;
  width: 100%;
  padding: var(--padding-footer);
  color: var(--blue-black);
  background-color: var(--back-footer);
  .icon.icon-footer {
    font-size: 1.2em;
    color: var(--black);
    margin: 0;
  }
  .layout-footer {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    gap: 2em;
    margin-bottom: 2.5em;
    img.logo {
      height: 2.5em;
      margin-inline: 1.5em;
    }
    img.logo-ar {
      height: 2.5em;
      margin-left: 1.5em;
    }
    .app-name {
      font-weight: 700;
      width: 50px;
      line-height: 16px;
    }
    .description-community {
      max-width: 80%;
      display: inline-block;
      text-align: justify;
      margin-left: 1.6rem;
      margin-top: 1.5em;
    }
    h2 {
      font-size: 1.2em;
      line-height: 1.8em;
    }
  }
  .footer-block-2 {
    font-size: 0.9em;
  }
  .social-media {
    border: 1px solid var(--gray-border);
    border-radius: 50%;
    width: 4em;
    height: 4em;
    margin: 0 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: var(--green);
      border: 1px solid var(--green);
      .icon.icon-footer {
        color: white;
      }
    }
  }
}

@media (max-width: 1300px) {
  .footer {
    .mobile-applications {
      img {
        width: 9em;
      }
    }
    .footer-block-2 {
      ul {
        flex-direction: column;
        gap: 0.8em;
      }
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    .layout-footer {
      // grid-template-columns: 2fr 2fr;
    }
    .mobile-applications {
      img {
        width: 7em;
      }
    }
  }
}

@media (max-width: 900px) {
  .footer {
    font-size: 0.8em;
    .layout-footer {
      gap: 1em;
      grid-template-columns: 2fr 2fr;
    }
    .social-media {
      width: 3em;
      height: 3em;
    }
    .layout-footer {
      .description-community {
        margin-left: 0px;
      }
      .section-description,
      .section-mobile {
        height: 13em;
        position: relative;
        hr {
          position: absolute;
          bottom: 0;
          width: 46vw;
        }
      }
      .description-community {
        margin-top: 0.5em;
      }
      img.logo {
        margin-left: 0;
      }
    }
    .footer-block-2 {
      margin-bottom: 2.5em;
    }
  }
}
@media (max-width: 400px) {
  .footer {
    .layout-footer {
      .section-description,
      .section-mobile {
        // height: 16em;
      }
      .description-community {
        min-width: 10em;
      }
    }
    .social-media {
      margin: 0 0.2em;
    }
  }
}
