button.green-button {
  background-color: var(--green);
  color: white;
  padding: 0.4em 1.6em;
  border-radius: var(--border-radius);
  font-size: 1.3em;
  &:hover {
    background-color: var(--green-hover);
  }
}
