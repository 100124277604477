.quran-page {
  .quran-body {
    height: 30vh;
    bottom: 0;
    gap: 2rem;
    padding: var(--padding-pages);
    display: grid;
    margin-top: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    height: fit-content;
  }
  .header {
    position: relative;
  }
  input[type="search"] {
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    height: 3em;
    width: 32em;
    padding-inline: 19px;
    outline: none;
    color: var(--green);
    background-image: url("../../assets/icons/search-icon.svg");
    background-position: 96% 9px;
    background-repeat: no-repeat;
    position: absolute;
    top: 40%;
    left: calc(50vw - 16em);
    opacity: 0.9;
    // to remove the icon clear form the search input
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
    }
    &.arabic {
      direction: rtl;
      text-align: right;
      background-position: 4% 9px;
    }
  }
  #video-quran {
    object-fit: cover;
    width: 100vw;
    height: var(--heightVideoPages);
    max-width: none;
  }
}

@media (max-width: 1300px) {
  .quran-page {
    .quran-body {
      grid-template-columns: 1fr 1fr;
      margin-top: 0px;
    }
  }
}

@media (max-width: 670px) {
  .quran-page {
    .quran-body {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    input[type="search"] {
      width: 83vw;
      background-position: 96% 5px;
      left: calc(50vw - 41.5vw);
    }
  }
}
