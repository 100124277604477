.dhikr-body {
  padding: var(--padding-pages);
  h1 {
    margin-bottom: 1em;
    color: var(--black);
  }
  .dhikr-container {
    display: grid;
    flex-wrap: wrap;
    gap: 2em;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 2.5em;
  }
}
@media (max-width: 1300px) {
  .dhikr-body {
    .dhikr-container {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
@media (max-width: 1000px) {
  .dhikr-body {
    .dhikr-container {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1.5em;
    }
  }
}

@media (max-width: 700px) {
  .dhikr-body {
    .dhikr-container {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media (max-width: 400px) {
  .dhikr-body {
    .dhikr-container {
      grid-template-columns: 1fr 1fr;
    }
  }
}
