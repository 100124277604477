.hadiths-body {
  padding: var(--padding-pages);
  .ahadith-section {
    margin-bottom: 2em;

    .ahadith-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 10em;
      gap: 2.5em;
      flex-wrap: wrap;
      .card-hadith {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--light-olive-green);
        border: 1px solid var(--back-golden-nav);
        border-radius: var(--border-radius);
        padding: 1.5em 2em 0.5em;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;

        cursor: pointer;

        img {
          width: 9em;
          border-radius: 3px;
          aspect-ratio: 2/3;
        }
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.08);
        }
      }
    }
    h3 {
      display: flex;
      color: var(--olive-brown);
      font-weight: 650;
    }
  }
}

@media (max-width: 450px) {
  .hadiths-body {
    padding: 2em 1.5em;
    .ahadith-section {
      .ahadith-container {
        gap: 1.5em;

        .card-hadith {
          padding: 1.5em 1.5em 0.5em;
          img {
            width: 6em;
          }
        }
      }
      h3 {
        font-size: 0.85em;
      }
    }
  }
}
