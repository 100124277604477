.quiz {
  .quiz-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--padding-pages);
    margin: 0 auto;
  }

  .quiz-text {
    font-size: 1.2rem;
    color: #1d342e;
    margin-bottom: 3.3em;
    align-items: center;
  }

  .wheel-container {
    position: relative;
    display: inline-block;
    direction: ltr;
    .wheel-container.arabic {
      direction: rtl;
    }
    img {
      position: absolute;
      z-index: 5;
      width: 15%;
      right: 10.6em;
      top: -35px;
      rotate: 1deg;
      flex-wrap: wrap;
    }
  }
  .button-turn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 48.7%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .button-turn {
    padding: 3.4em;
    width: 100px;
    height: 100px;
    background-color: #ebebeb;
    color: #1d342e;
    border-radius: 50%;
    font-size: 1.1em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    padding-bottom: 4em;

    .number-question-bar {
      font-size: 1.25em;
      font-weight: bold;
      color: #1d342e;
      margin-bottom: 0.5em;
    }

    .progress-bar {
      width: 76%;
      height: 0.5em;
      background-color: #e0e0e0;
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      .progress {
        height: 100%;
        background-color: #0a6629;
        transition: width 0.3s ease;
      }
    }
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-ofQuestion {
    width: 12em;
  }

  .questionner-container {
    text-align: center;
    margin: 2em auto;
    margin-bottom: 16em;
    width: 100%;
    max-width: 90%;

    .options-questionner {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: -4em 10em;
      padding: 5em 2em 3em 2em;
      background-color: #f5f5f4;
      border-radius: 16px;
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);

      .option-button {
        font-size: 1.15em;
        color: #1d342e;
        padding: 0.75em 8em;
        margin: 10px;
        border: 1px solid #ccc;
        border-radius: 8px;
        cursor: pointer;
        width: 70%;
        text-align: center;
        background-color: #f5f5f4;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          background-color: #e0e0e0;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &.selected {
          background-color: #f5f5f5;
          border: 2px solid #406259;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &.correct {
          background-color: #255637;
          border: #255637;
          color: #ffffff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &.incorrect {
          background-color: #bd1000;
          border: #bd1000;
          color: #ffffff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .submit-button {
      margin-top: 7.5em;
      margin-bottom: 6em;
      padding: 1.1em 11.5em;
      font-size: 1em;
      background: var(--gradient-submit) !important;
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    .next-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 9.5em;
      cursor: pointer;

      .next {
        margin-top: 4em;
        font-size: 1.14em;
        color: #406259;
        transition: color 0.3s ease;
      }
    }
  }

  .quiz-finished-overlay {
    position: relative;
    text-align: center;
    background-color: #f5f5f4;
    margin-top: -3em;
    padding: 1em 9em;
    border-radius: 10px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.1);
  }
  .image-score-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .image-score {
      width: 11.25em;
      z-index: 10;
    }
    .score-message {
      color: #1d342e;
      font-size: 2.5em;
      font-weight: 600;
      margin-top: 1.5em;
    }
    button {
      background-color: #406259;
      color: #ffffff;
      padding: 1em 6em 1em 6em;
      border-radius: 5px;
      margin-top: 2em;
    }

    .icon {
      width: 5em;
      margin: 1em 6em;
    }
    .YourScore {
      color: #1d342e;
      font-size: 1.5em;
      font-weight: 400;
      padding-bottom: 1em;
    }
    .play-again {
      position: absolute;
      bottom: -7em;
      transform: translateX(-46%);
      background: var(--gradient-playagain);
      color: white;
      padding: 1em 8em;
      border-radius: 8px;
      margin: 2em -1em;

      &.arabic {
        transform: translateX(46%);
      }
    }
  }

  .quiz-finished-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2em;

    margin-bottom: 14em;
  }

  .bhdLno {
    width: 25em !important;
    height: 25em !important;
  }
}
@media (max-width: 1300px) {
  .quiz.image-ofQuestion {
    width: 12em;
  }
  .quiz .questionner-container {
    margin: 2em auto;
    .options-questionner {
      padding: 5em 1em 3em 1em;
    }
  }
}

@media (max-width: 1000px) {
  .quiz .image-ofQuestion {
    width: 11.4em;
  }

  .quiz .questionner-container {
    margin: 3em auto;
    .next-container {
      padding-right: 5.2em;
    }
    .options-questionner {
      padding: 4em 1em 2em 1em;
      margin: -2.65em 4.7em;
    }
  }
  .quiz .image-score-container {
    .image-score {
      width: 10.5em;
    }
  }
  .quiz .quiz-finished-overlay {
    padding: 1em 7.2em;

    .score-message {
      font-size: 2em;
    }
    .YourScore {
      font-size: 1.7em;
    }
    .icon {
      width: 4.5em;
    }
  }
}
@media (max-width: 1300px) {
  .quiz .questionner-container {
    margin-bottom: 16em;
  }
}

@media (max-width: 800px) {
  .quiz .progress-bar {
    width: 94% !important;
  }
  .quiz .image-ofQuestion {
    width: 11.7em;
  }
  .quiz .questionner-container {
    margin: 3em 3em;
    margin-bottom: 16em;

    .next-container {
      padding-right: 2.1em;
    }
    .options-questionner {
      padding: 4em 1em 2em 1em;
      margin: -2.65em 1.5em;
      .option-button {
        width: 76%;
      }
    }
  }
  .quiz .quiz-finished-overlay {
    padding: 1em 3.2em;

    .score-message {
      font-size: 2em;
    }
    .YourScore {
      font-size: 1.7em;
    }
    .icon {
      width: 4.3em;
    }
    .play-again {
      padding: 1.14em 7em;
      width: 21.5em;
      &.arabic {
        transform: translateX(40%);
        padding: 1em 2em;
        width: 19em;
      }
    }
  }
}
@media (max-width: 600px) {
  .quiz .wheel-container img {
    right: 10.7em;
  }
  .quiz .image-ofQuestion {
    width: 10.3em;
  }

  .quiz .questionner-container {
    margin: 3em 2em;
    margin-bottom: 16em;
    .next-container {
      padding-right: 1em;
    }
    .submit-button {
      padding: 1.75em 11.15em;
      font-size: 0.8em;
    }

    .options-questionner {
      margin: -2.65em 1.3em;
      padding: 3.5em 2.25em;

      .option-button {
        font-size: 1.1em;
        width: 96%;
      }
    }
  }
  .quiz .image-score-container {
    .image-score {
      width: 9.8em;
    }
  }
  .quiz .quiz-finished-overlay {
    padding: 1em 0.5em;

    .score-message {
      font-size: 1.8em;
    }
    .YourScore {
      font-size: 1.43em;
    }
    .icon {
      width: 4em;
    }
    .play-again {
      padding: 1.05em 6em;
      width: 19em;
      &.arabic {
        transform: translateX(40%);
        padding: 1em 4em;
        width: 16.4em;
      }
    }
  }
}

@media (max-width: 460px) {
  .quiz .image-score-container {
    .image-score {
      width: 8em;
    }
  }
  .quiz .quiz-finished-overlay {
    width: 26.8em;
    .score-message {
      font-size: 1.75em;
    }
    .YourScore {
      font-size: 1.4em;
    }
    .icon {
      width: 3.2em;
      margin: 0.3em 5em;
    }
    .play-again {
      padding: 1em 5em;
      width: 16em;
    }
  }
}
@media (max-width: 420px) {
  .quiz .image-ofQuestion {
    width: 8em;
  }

  .quiz .questionner-container {
    margin: 2em 1.5em;
    margin-bottom: 16em;
    .submit-button {
      padding: 1.5em 8em;
      font-size: 0.9em;
    }
    .next-container {
      padding-right: 1em;
    }

    .options-questionner {
      margin: -2.5em 0.5em;
      padding: 3.5em 0.9em;

      .option-button {
        width: 100%;
        margin: 0.5em 10em;
        font-size: 1em;
      }
    }
  }
  .quiz .image-score-container {
    .image-score {
      width: 7.82em;
    }
  }
  .quiz .quiz-finished-overlay {
    padding: 1.2em 0em;
    width: 22.8em;
    .score-message {
      font-size: 1.67em;
    }
    .YourScore {
      font-size: 1.25em;
    }
    .icon {
      width: 2.85em;
      margin: 0.3em 4.6em;
    }
    .play-again {
      padding: 0.9em 4em;
      width: 15em;
      &.arabic {
        transform: translateX(40%);
        padding: 0.8em 2em;
        width: 13em;
      }
    }
  }
}
