:root {
  --shadow-box: 0rem 0 1rem rgba(0, 0, 0, 0.15);
  --full-width: calc(100vw - 7px); // the 7px is the width of scrollbar x
  --padding-pages: 2em 4em;
  --padding-about-page: 3em 5em;
  --padding-footer: 3em 3em;
  --border-radius: 10px;
  --heightVideoPages: 13.5em;
  --gradient-header: linear-gradient(
    to Bottom,
    rgb(202, 196, 172, 0.2) 0%,
    white 100%
  );
  --gradient-submit: linear-gradient(to right, #1d342e 0%, #406259 100%);
  --gradient-playagain: linear-gradient(
    to right,
    #406259 0%,
    #3d5e56 15%,
    #395951 23%,
    #37574e 44%,
    #34524a 65%,
    #37574e 75%,
    #395951 81%,
    #3d5e56 92%,
    #406259 100%
  );
}

@media (max-width: 1500px) {
  :root {
    --heightVideoPages: 11.5em;
  }
}

@media (max-width: 1300px) {
  :root {
    --padding-pages: 2em 3em;
    // --padding-footer: 4em 7em;
  }
}

@media (max-width: 800px) {
  :root {
    --padding-pages: 2em 2em;
    --padding-footer: 1em 3em;
    --padding-about-page: 2em 4em;
    // --heightVideoPages: 14.5em;
  }
}

@media (max-width: 400px) {
  :root {
    --padding-footer: 1em 2em;
  }
}
