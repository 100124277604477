.container-surah {
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-box);
  padding: 0.5em 1em;
  color: var(--green);
  direction: ltr;
  cursor: pointer;
  .surah {
    padding: 1em 1.5em;
    border-radius: 8px;
  }
  .arabic-name {
    text-align: right;
    font-family: "Amiri Quran";
  }
  .num-sorah {
    width: 3.5em;
  }
  &.surahPage {
    box-shadow: unset !important;
    border-radius: 0px;
    width: 90%;
    padding: 1em 1em 0em 1em;
    &:not(:has(.selectedSorah)) {
      border-bottom: 1px solid var(--gray-border);
    }
    .selectedSorah,
    :not(.selectedSorah):hover {
      background-color: var(--green);
      .surah-name,
      .arabic-name {
        color: white;
        font-weight: 500;
        transform: translate(0px, 9px);
      }
      .arabic-name {
        font-family: "Amiri";
      }
      .num-sorah span {
        color: var(--green);
        background-color: white;
      }
      .type-sorah-container {
        margin-top: 0.3rem;
      }
    }
  }

  // page of list surahs
  &:not(.surahPage):hover {
    background-color: var(--green);
    .surah-name,
    .arabic-name {
      color: white;
      font-weight: 500;
      transform: translate(0px, 9px);
    }
    .arabic-name {
      font-family: "Amiri";
    }
    .num-sorah span {
      color: var(--green);
      background-color: white;
    }
    .type-sorah-container {
      margin-top: 0.3rem;
    }
  }
}

.num-sorah {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    transform: translateY(-34px);
    color: white;
  }
  img {
    max-width: inherit;
  }
}
.type-sorah,
.number-ayahs {
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;
  background-color: var(--gray) !important;
  padding: 0.3em 0.6em;
  border-radius: 5px;
  font-size: 0.8em;
  gap: 0.3em;
  height: 2em;
  overflow: hidden;
  font-size: 0.7em;
  img {
    background-color: var(--gray) !important;
  }
}

@media (max-width: 1000px) {
  .container-surah {
    .num-sorah {
      width: 3em;
    }
    &.surahPage {
      border-radius: 12px;
      border: 1px solid var(--gray-border);
      img {
        max-width: 3em;
      }
    }
    .surah {
      padding: 1em 0.5em;
    }
    .type-sorah,
    .number-ayahs {
      font-size: 0.6em;
    }
    .number-ayahs {
      width: 7em;
    }
  }
}
