.dropdown-list {
  border-radius: var(--border-radius);
  border: 1px solid #cacaca;
  overflow: hidden;
  height: 3.5em;

  .name-selected {
    background-color: var(--back-transparent-1);
    height: 100%;
    width: 18em;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--green);
    font-weight: 600;

    cursor: pointer;

    & > span {
      font-size: 1em;
      text-align: center;
    }
    &:has(+ .list-items-container) {
      // border-bottom: 1px solid var(--green);
      // height: 3.3em;
    }
    &:not(:has(+ .list-items-container)) {
      // height: 3.3em;
    }
  }
  .list-items-container {
    width: 18em;
    padding: 0.7em 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1.2em;
    position: absolute;
    top: 3.6em;
    z-index: 9;
    background-color: var(--back-transparent-2);
    border-radius: var(--border-radius);
    .item-name {
      cursor: pointer;
    }
  }
}

@media (max-width: 900px) {
  .dropdown-list {
    height: 3em;
    .name-selected {
      width: 15em;
    }
    .list-items-container {
      width: 15em;
      top: 3.1em;
    }
  }
}
@media (max-width: 800px) {
  .dropdown-list {
    .name-selected {
      width: 29em;
    }
    .list-items-container {
      width: 29em;
      top: 3.1em;
    }
  }
}
@media (max-width: 400px) {
  .dropdown-list .name-selected {
    width: 24em;
  }
  .list-items-container {
    width: 24em !important;
    top: 3.1em;
  }
}
