$width-hr: 200px;
$width-circle: 14px;
.prophets-body {
  padding: var(--padding-pages);
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(8em);
  .stories-container {
    position: relative;
    hr {
      height: 2px;
      border-top-width: 0px;
      background-color: var(--golden);
      position: absolute;

      &.horizental {
        width: $width-hr;
      }
      &.vertical {
        width: calc(20em * 25);
        transform: rotate(90deg);
        top: calc(9.75em * 25);
        left: calc(-9.25em * 25);
        transition: opacity 1.3s ease-in;
      }
      &.hidden-hr {
        opacity: 0;
      }
    }
    .prophet-story {
      position: relative;
      width: 37em;
      height: 20em;
      transition: opacity 1s ease-in, transform 1s ease-in-out;
      &.hidden-story {
        opacity: 0;
        transform: translateY(100px);
      }
      &.visible-story {
        opacity: 1;
        transform: translateY(-30px);
      }

      .prophet-circle {
        position: absolute;
        width: $width-circle;
        height: $width-circle;
        border: 2px solid var(--golden);
        border-radius: 50px;
        top: -6px;
        left: 293px;
        background: white;
        z-index: 99;
      }
      img {
        position: absolute;
        width: 10em;
        top: -68px;
        transition: scale 0.3s ease-in-out, opacity 1s ease-in-out;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
          scale: 1.08;
        }
      }
      h1 {
        width: fit-content;
        position: absolute;
        top: -2em;
      }

      p {
        position: absolute;
        top: 2em;
        width: calc(24vw + 6em);
      }
      &:nth-child(2n + 1) {
        h1 {
          top: -2em;
          left: 3em;
          text-align: right;
          width: 8em;
        }
        img {
          left: calc($width-hr + 10em);
        }
        hr.horizental {
          left: calc(3.25em + 48px);
        }
        p {
          left: calc($width-hr - 27vw);
          text-align: right;
        }
      }
      &:nth-child(2n) {
        h1 {
          left: 14em;
        }
        img {
          left: calc($width-hr - 8em);
        }
        hr.horizental {
          left: 19em;
        }
        p {
          left: 20em;
          text-align: left;
        }
      }
    }
  }
}

#video-prophets {
  object-fit: cover;
  width: 100vw;
  height: var(--heightVideoPages);
  max-width: none;
}

@media (max-width: 1300px) {
  .prophets-body {
    .prophet-story {
      hr {
        &.vertical {
          left: 94px;
        }
      }
      .prophet-circle {
        left: 257px !important;
      }
      &:nth-child(2n + 1) {
        h1 {
          left: 190px;
        }
        img {
          left: calc(200px + 7em);
        }
        hr.horizental {
          left: calc(3.25em + 22px) !important;
        }
      }
      &:nth-child(2n) {
        // hr.horizental {
        //   left: calc($width-hr + 64px);
        // }
        p {
          left: 300px;
        }
        img {
          left: calc(180px - 8em) !important;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .prophets-body {
    .prophet-story {
      .prophet-circle {
        left: 237px !important;
      }
      &:nth-child(2n + 1) {
        img {
          left: calc(144px + 10em) !important;
        }
        p {
          left: calc(170px - 27vw) !important;
        }
        hr.horizental {
          left: calc(3.25em + 7px) !important;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .prophets-body {
    .stories-container {
      .prophet-story {
        .prophet-circle {
          left: 218px !important;
        }
        hr {
          width: 180px !important;
        }
        img {
          width: 8em;
          top: -45px;
        }
        &:nth-child(2n + 1) {
          p {
            left: calc(150px - 21vw) !important;
            width: calc(24vw + 4em);
            text-align: justify;
          }
          img {
            left: calc(144px + 8.5em) !important;
          }
        }
        &:nth-child(2n) {
          p {
            text-align: justify;
          }
          img {
            left: calc(180px - 6em) !important;
          }
        }
        p {
          width: calc(24vw + 4em);
        }
      }
    }
  }
  .prophets-page .pages-position {
    overflow: hidden;
  }
}
