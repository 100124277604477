.card-dhikr {
  box-shadow: var(--shadow-box);
  height: 12em;
  border-radius: 10px;
  cursor: pointer;
  transition: scale 0.2s ease-in-out, opacity 1s ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .image-container {
    width: 100%;
    position: relative;
    transition: transform 0.3s ease-in-out;
  }
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2em;
    color: var(--black);
    font-weight: 600;
    font-size: 1em;
    color: var(--black);
    padding: 0.2em 0.5em;
    position: absolute;
    top: calc(100% - 2em);
    left: 0;
    right: 0;
    z-index: 10;
    border-radius: 0 0 10px 10px;
  }
  .image-dhikr {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
  }

  .title-container {
    width: 100%;
    background: white;
    text-align: center;
    padding: 0.25rem 0;
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    height: 2.4em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > img {
    border-radius: 10px;
    width: 100%;
    height: auto;
    max-height: 14rem;
    object-fit: cover;
    clip-path: inset(0 0 2em 0);
  }
  &:hover {
    opacity: 0.9;
    scale: 1.05;
    transition: scale 0.3s ease-in-out, opacity 1s ease-in-out;
  }
}
@media (max-width: 1300px) {
  .card-dhikr {
    height: 14em;
  }
}
@media (max-width: 1100px) {
  .card-dhikr {
    height: 13em;
  }
}
@media (max-width: 1000px) {
  .card-dhikr {
    height: 13.5em;
  }
}

@media (max-width: 700px) {
  .card-dhikr {
    height: 16.6em;
    .title-container {
      height: 3em;
    }
  }
}
@media (max-width: 550px) {
  .card-dhikr {
    height: 14em;
  }
}
@media (max-width: 460px) {
  .card-dhikr {
    height: 13em;
  }
}
@media (max-width: 432px) {
  .card-dhikr {
    height: 11.5em;
  }
}

@media (max-width: 349px) {
  .card-dhikr {
    height: 10em;
    .title-container {
      height: 2.5em;
    }
  }
}
