@keyframes rotate-right {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(45deg);
  }
}
@keyframes rotate-left {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes rotate-img-left {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-45deg);
  }
}
@keyframes rotate-img-right {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes rotate-backright {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(45deg);
  }
}
@keyframes rotate-backleft {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(-45deg);
  }
}

section {
  &.section-1 {
    height: 90vh;
    top: 0;
    left: 0;
    width: var(--full-width);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .allah-name {
      position: absolute;
      top: 14em;
      z-index: 20;
      right: 30vw;
      opacity: 0.9;
      width: 40vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    // &::before {
    //   content: "";
    //   background-image: url("../../assets/images/background-home.png");
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-size: cover;
    //   filter: brightness(0.65) contrast(100%);
    //   position: absolute;
    //   width: var(--full-width);
    //   height: 90vh;
    // }
    .content {
      z-index: 2;
      color: white; /* Couleur du texte */
      display: flex;
      flex-direction: column;
      align-items: center;
      #video-home {
        width: 100vw;
        height: 49em;
        object-fit: cover;
        filter: brightness(0.65) contrast(100%);
        object-position: left;
        position: absolute;
        top: 0;
      }
    }

    h1 {
      font-size: 3vmax;
      font-weight: bold;
      color: white;
      text-align: center;
      font-family: "Poly";
    }
    img {
      width: 38vw;
    }
  }
}

.home {
  display: flex;
  flex-direction: column;
}

.home-2 {
  position: absolute;
  top: 58em;
  width: var(--full-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 2.3em;
    color: var(--green);
    font-weight: 700;
    text-align: center;
    font-family: "Poly";
  }
  p {
    text-align: center;
    margin: 1.2em 0 2.2em 0;
  }
  .section-3 {
    width: 70%;
    p {
      font-size: 1.3em;
    }
  }
  .section-4 {
    margin-top: 2.4em;
    width: 90%;
    > h1 {
      margin: 1em 0;
    }
  }
  .section-5 {
    background: url("../../assets/images/backgroung-services.svg")
      var(--back-gray);
    width: 100%;
    margin-top: 7vw;
    padding: 7vmin;

    h2.piller-name {
      color: var(--green);
      font-weight: 700;
      font-size: 1.5em;
    }
  }
  .section-6 {
    width: 100%;
    direction: ltr;
    background-position-x: right;
    position: relative;
    height: 25em;
    margin: 4em 0;
    .recitation-container {
      h1 {
        text-align: inherit;
        font-size: 2em;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../assets/images/backgroung-recitation-2.png");
      background-repeat: no-repeat;
      z-index: 1;
      background-size: cover;
      background-position: center;
    }
    & > div {
      z-index: 2;
      position: absolute;
      top: 2em;
      display: flex;
      flex-direction: column;
      width: 49em;
    }
    .section-recitation {
      width: 42em;
      position: relative;
    }
  }
  .col-span-2 {
    display: "flex";
  }
  .section-8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 7em;
    .mobile-card {
      display: flex;
      gap: 2em;
      img {
        width: 35em;
      }
      .mobile-card-details {
        width: 36em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 33em;
        h1 {
          color: var(--golden);
          font-size: 2.5em;
          text-align: left;
        }
        p {
          font-size: 1.3em;
          text-align: justify;
        }
      }
    }
  }
}
.card-service {
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
  cursor: pointer;
  max-width: 40em;
  transition: all 0.1s ease-in-out;
  box-shadow: var(--shadow-box);

  &.selectedService {
    background: url("../../assets/images/backgroung-services.svg") var(--green);
    color: white;
  }
  &.unselectedService {
    background: url("../../assets/images/backgroung-services.svg")
      var(--back-gray);
    color: var(--green);
  }
  h2 {
    font-size: 1.5rem;
    text-align: center;
    line-height: 2em;
    font-weight: 600;
  }
  img {
    max-height: 6em;
    margin-bottom: 0.5em;
  }
}
.pillers {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.piller-container,
.piller {
  border: 2px solid var(--golden);
  width: 8vw;
  height: 8vw;
}
.piller-container {
  background: white;
  display: flex;
  position: absolute;
  top: -2px;
  left: -2px;
  transform: rotate(-45deg);
  transform-origin: center;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
  }
}

.piller {
  position: relative;
  transform: rotate(0deg);
  // transform-origin: center;
  // -webkit-animation: item-hover-off 1s;
  // -moz-animation: item-hover-off 1s;
  // animation: item-hover-off 1s;
  &:hover {
    animation: rotate-left 0.3s linear forwards;
    .piller-container {
      animation: rotate-right 0.3s linear forwards;
      img {
        animation: rotate-img-left 0.3s linear forwards;
      }
    }
  }
  &:not(:hover) {
    animation: rotate-backright 0.3s linear forwards;

    .piller-container {
      animation: rotate-backleft 0.3s linear forwards;
      img {
        animation: rotate-img-right 0.3s linear forwards;
      }
    }
  }
}

@media (max-width: 1500px) {
  .card-service {
    max-width: 34em;
  }
}

@media (max-width: 1150px) {
  .card-service {
    max-width: 30em;
  }
  h2.piller-name {
    font-size: 1.2em !important;
  }
  .home-2 {
    .section-6 {
      padding: 3vmin;
      height: 23em;
    }
  }
}

@media (max-width: 1000px) {
  .card-service {
    max-width: 24em;
  }
  .home-2 {
    top: 63em;
    p {
      margin: 1.5em 0 1.5em 0;
    }
    .piller-container,
    .piller {
      width: 10vw;
      height: 10vw;
    }
    .section-6 {
      padding: 2vmin;
      height: 20em;
      .section-recitation {
        width: 80%;
      }
      & > div {
        width: 89%;
        z-index: 2;
        position: absolute;
        top: 0em;
      }
    }
    .section-8 {
      padding: 4em;
      .mobile-card {
        gap: 1em;
        img {
          width: 31em;
        }
        .mobile-card-details {
          h1 {
            font-size: 2em;
          }
          p {
            font-size: 1.1em;
          }
        }
      }
    }
  }
  section.section-1 {
    height: 70vh;
    img,
    .allah-name {
      width: 50vw;
    }
    .allah-name {
      right: 26vw;
    }
  }
  .pillers {
    width: 100%;
  }
}
@media (max-width: 740px) {
  .card-service {
    max-width: 100%;
  }
  .home-2 {
    .section-6 {
      & > div {
        width: 89%;
        z-index: 2;
        position: absolute;
        top: 1.5em;
      }
    }
    .section-8 {
      padding: 2em;
      .mobile-card {
        flex-direction: column;
        margin-bottom: 4em;
        align-items: center;
        img {
          width: 23em;
        }
        .mobile-card-details {
          height: auto;
          width: auto;
          align-items: center;
          transform: translateY(-20px);
        }
      }
    }
  }
}
@media (max-width: 670px) {
  section.section-1 {
    height: 66vh;
    img,
    .allah-name {
      width: 63vw;
    }
    .allah-name {
      right: 20vw;
    }
    .content #video-home {
      object-position: -80px 0px;
    }
  }
  .home-2 {
    top: 61em;
    .section-3 {
      width: 85%;
    }
    .section-5 h2.piller-name {
      font-size: 1em !important;
    }
    .section-6 {
      .section-recitation {
        width: 95%;
      }
      h1 {
        font-size: 1.6em !important;
      }
    }
  }
}

@media (max-width: 450px) {
  section {
    &.section-1 {
      h1 {
        width: 90%;
      }
      img,
      .allah-name {
        width: 80vw;
      }
      .allah-name {
        right: 10vw;
      }
    }
  }
}
