.recitation {
  background-color: white;
  border-radius: 18px;
  border: 1px solid #cacaca;
  height: 12em;
  padding: 5vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .list-sorahs-container {
    height: 24vh;
    overflow-y: scroll;
    background-color: var(--back-gray);
    padding: 0.7em 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .sorah-name {
      cursor: pointer;
      height: auto;
      min-height: 2.5em;
      margin: 0;
      overflow: hidden;
    }
  }
  .homePage {
    position: absolute;
    top: 2.8em;
    left: 4%;
  }
  ::-webkit-scrollbar-thumb {
    min-height: 25px;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
}

.dropdown-quran {
  border-radius: var(--border-radius);
  border: 1px solid var(--green);
  overflow: hidden;
  position: absolute;
  left: 36%;
  top: 2.8em;
  .coran-name {
    background-color: var(--back-gray);
    height: 3em;
    width: 18em;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--green);
    font-weight: 600;
    cursor: pointer;
    & > span {
      font-size: 1.2em;
    }
    &:has(+ .list-sorahs-container) {
      border-bottom: 1px solid var(--green);
      // height: 3.3em;
    }
    &:not(:has(+ .list-sorahs-container)) {
      // height: 3.3em;
    }
  }
}

.recitation-with-text {
  height: 3.5em;
  width: 34em;
  border-radius: var(--border-radius);
  border: 1px solid #cacaca;
  padding-inline: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: var(--back-transparent-1);
  .icon {
    font-size: 2em !important;
    &.forward {
      font-size: 1.2em !important;
    }
  }
  #audio-bar {
    height: 5px;
  }
}
#audio-bar {
  width: 80%;
  height: 8px;
  margin: 0 1em;
  cursor: pointer;
}
.recitation-section {
  input[type="range"]::-webkit-slider-thumb {
    width: 13px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    background: var(--green);
    box-shadow: -40vw 0 0 40vw var(--green);
    border-radius: 50%;
  }
  .icon {
    color: var(--green);
    cursor: pointer;
    font-size: 3em;
    &.forward {
      font-size: 1.7em;
    }
    &:hover {
      color: var(--green-hover);
    }
  }
}

@media (max-width: 900px) {
  .dropdown-quran .coran-name {
    padding: 6px 13px;
  }
  .icon {
    margin: 0 6px;
  }
  .recitation {
    height: 10em;
  }
  .recitation-with-text {
    width: 31em;
    height: 3em;
  }
  .recitation-section {
    .icon {
      font-size: 2.5em;
    }
  }
}

@media (max-width: 800px) {
  .recitation-with-text {
    width: 29em;
  }
  .dropdown-quran .coran-name {
    width: 20em;
  }
}
@media (max-width: 460px) {
  .dropdown-quran .coran-name {
    width: 15em;
  }
}

@media (max-width: 400px) {
  .recitation-with-text {
    width: 24em;
  }
  .dropdown-quran .coran-name {
    width: 13.5em;
  }
  .recitation-section .icon.forward {
    font-size: 1.25em;
  }
}
