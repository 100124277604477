.google-map {
  height: 100%;
  width: 100%;
  .distances {
    .label-distance {
      background-color: var(--green-map);
      color: white;
      padding: 0.1em 0.5em;
      border-radius: 6px;
      font-size: 1em;
      display: none;
      transform: translateX(-4px);
      &.label-visible {
        display: block;
      }
    }
    hr {
      height: 3px;
      box-sizing: content-box;
      background: var(--green-map);
      background-clip: content-box;
      width: 0.9em;
      position: absolute;
      transform: rotate(90deg);
      border: none;
      rotate: 90deg;
      transform: translate(-17px, 0px);
      cursor: pointer;
      padding: 10px;
      cursor: pointer;
      &:hover {
        + span {
          display: block;
        }
      }
      &.selected-value {
        width: 1.5em;
        height: 6px;
        border-radius: 6px;
        transform: translate(-18px, 0px);
      }
    }
  }
}

#muslim-map {
  height: 100%;
}

#radius-range {
  width: 32em;
  position: absolute;
  bottom: 10px;
  right: -27.75em;
  input[type="range"] {
    background-color: var(--green-map);
    &::-webkit-slider-thumb {
      width: 0px;
      -webkit-appearance: none;
      appearance: none;
      height: 15px;
      background: var(--green-map);
      border-radius: 50%;
    }
  }
}

@media (max-width: 500px) {
  #radius-range {
    width: 22em;
  }
}

@media (max-width: 485px) {
  #radius-range {
    right: -19.75em;
  }
}
