.ghousl-body {
  display: flex;
  flex-direction: column;
  padding: var(--padding-pages);
  height: calc(100vh - 8em - 72px);
  justify-content: space-around;
  .step-ghousl {
    display: flex;
    flex-direction: row;
    margin: 2em;
    img {
      width: 18em;
      margin-top: 2em;
      margin-inline: 3em;
    }
    .step-ghousl-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        color: var(--golden);
        font-size: 2em;
        margin-bottom: 0.5em;
        text-align: center;
      }
      p {
        font-size: 1.2em;
        padding: 1em 2em;
        text-align: justify;
      }
    }
  }
}

@media (max-width: 1000px) {
  .ghousl-body {
    .step-ghousl {
      margin: 2em 0em;
      img {
        width: 16em;
        margin-inline: 1em;
      }
    }
  }
}

@media (max-width: 600px) {
  .ghousl-body {
    .step-ghousl {
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 0;
        margin-bottom: 2em;
      }
      .step-ghousl-details {
        h1 {
          font-size: 1.6em;
        }
      }
    }
  }
}
