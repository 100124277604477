.alert-prayer {
  background-color: #f4f4f4;
  padding: 3em 6.75em 3em 6.75em;
  border-radius: 5px;
  margin: 0px auto;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 5em;
    margin-bottom: 1em;
  }
}

.alert-home {
  background-color: #f4f4f4;
  padding: 1em;
  border-radius: 5px;
  text-align: justify;
  max-width: 616px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 4em;
    margin-right: 15px;
  }
}

.alert-mosque {
  padding: 7em 5.75em 7em 5.75em;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 5em;
    margin-bottom: 1em;
  }
}

@media (max-width: 800px) {
  .alert-prayer {
    padding: 3em 3em;
    width: 97%;
  }
  .alert-home {
    padding: 2em 3em;
    max-width: 90%;
  }
  .alert-mosque {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .alert-prayer {
    padding: 2em 2em;
    width: 92.95%;
    margin-bottom: 1em;
    margin-left: 0.5em;
  }
  .alert-home {
    padding: 1.75em 4em;
    max-width: 95%;
  }
  .alert-mosque {
    padding: 3.25em 3em;
    width: 95%;
  }
}

@media (max-width: 400px) {
  .alert-prayer {
    padding: 2em 2em;
    width: 93.9%;
    img {
      width: 4.75em;
    }
  }
  .alert-home {
    padding: 1em 1em;
    max-width: 100%;
  }
  .alert-mosque {
    padding: 1.55em 3em;
    width: 100%;
    img {
      width: 4em;
    }
  }
}
