.prayer-times {
  width: 90vw;
  border-radius: 12px;
  background-color: white;
  padding: 2.8em;
  position: absolute;
  top: 44em;
  left: 4vw;
  box-shadow: var(--shadow-box);
  z-index: 99;
  h1 {
    font-weight: 700;
  }
  .prayer-name {
    font-size: 0.9em;
    margin-bottom: 0.2em;
    font-weight: 600;
  }
  .next-prayer {
    font-weight: 800;
  }
}
.display-times {
}

.time {
  background-color: var(--golden);
  color: white;
  width: 6em;
  height: 2.7em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .prayer-times {
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    .prayer-date {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
@media (max-width: 670px) {
  .prayer-times {
    padding: 2em;
  }
}
@media (max-width: 530px) {
  .time {
    width: 5.5em;
  }
}
@media (max-width: 470px) {
  .time {
    width: 4.6em;
  }
  .prayer-times {
    padding: 1em;
  }
}
@media (max-width: 380px) {
  .time {
    width: 4em;
  }
  .prayer-times .prayer-name {
    font-size: 0.75em;
  }
}
