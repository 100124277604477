.rakaa-body {
  padding: var(--padding-pages);
  .all {
    gap: 2em;
  }
  h2 {
    line-height: 1.2em;
    font-size: 1em;
  }
  .rakaa-selected,
  .list-steps {
    padding: 2em;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    height: 34em;
    box-shadow: 0px 1px 11px #4b3f1e2e;
    background-color: var(--back-salat);
  }

  .rakaa-selected {
    width: 27em;
  }

  .list-steps {
    .steps-container {
      display: flex;
      flex-direction: column;
      gap: 1.5em;
      align-items: flex-start;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;

      .step-salat {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .num-step-salat {
          background-color: var(--back-2-brown);
          width: 3.5em;
          height: 3.5em;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--brown);
          z-index: 9;
          span {
            font-size: 1.2em;
          }
        }
        .title-step-salat {
          border-radius: var(--border-radius);
          border: 1.5px solid var(--back-2-brown);
          width: 18em;
          height: 2.9em;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          h1 {
            font-size: 0.9em;
            color: var(--blue-black);
            margin-inline: 2em;
            text-align: left;
          }
        }
        &.selected-step {
          .num-step-salat {
            background-color: var(--green);
            span {
              color: white;
            }
          }
          .title-step-salat {
            border: 1.5px solid var(--green);
          }
        }
      }
    }

    h1 {
      color: var(--brown);
      font-size: 1.6em;
    }

    /** scroll bar **/
    ::-webkit-scrollbar-thumb {
      background: var(--back-2-brown);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: var(--brown);
    }
  }

  .card-prayerRight {
    padding: 1em;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    width: 13em;
    height: 22em;
    box-shadow: 0px 1px 11px #4b3f1e2e;
    background-color: var(--back-salat);
    opacity: 52%;
    margin-top: 6em;
  }
  .btn-arrow {
    background-color: var(--back-salat);
    padding: 1em;
    width: 3em;
    border-radius: var(--border-radius);
    box-shadow: 0px 1px 11px #4b3f1e2e;
  }
}

@media screen and (max-width: 1300px) {
  .rakaa-body {
    p {
      font-size: 14px;
    }
    .special {
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .rakaa-body {
    .rakaa-selected,
    .list-steps {
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1em;
    }
    .list-steps {
      .steps-container {
        .step-salat {
          .title-step-salat {
            width: 16em;
          }
        }
      }
    }
    .rakaa-selected {
      width: 23em;
    }
  }
}

@media screen and (max-width: 1000px) {
  .rakaa-body {
    .card-prayerRight {
      display: none;
    }
  }
}

@media screen and (max-width: 850px) {
  .rakaa-body {
    .rakaa-selected,
    .list-steps {
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 1em;
    }
    .list-steps {
      .steps-container {
        .step-salat {
          .title-step-salat {
            width: 15em;
          }
        }
      }
    }
    .rakaa-selected {
      width: 25em;
    }
  }
}

@media screen and (max-width: 750px) {
  .rakaa-body {
    .current {
      display: none;
    }
    .list-steps {
      height: 68vh;
      margin-bottom: 0em;
      .steps-container {
        .step-salat {
          .title-step-salat {
            width: 58vw;
          }
        }
        .details-step {
          width: 63vw;
          padding-inline: 2em;
        }
      }
    }
  }
}
