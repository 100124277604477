nav.nav-bar {
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em 1em 1em;
  font-family: "Poly";
  backdrop-filter: blur(5px);
  height: 72px;
  direction: ltr;
  li {
    cursor: pointer;
    width: fit-content;

    a,
    span {
      margin: 0 8px;
      font-weight: 600;
    }
  }
  img {
    height: 2.5em;
    margin-inline: 1.5em;
  }
  .app-name {
    font-weight: 700;
    width: 50px;
    line-height: 16px;
  }
  .drop-down-section {
    width: 10rem;
    height: 100%;
    display: none;
    position: absolute;
    ul {
      display: flex !important;
      flex-direction: column;
      gap: 0.7rem;
      position: absolute;
      top: 1.5rem;
      padding: 0.5rem 0;
      width: 90%;
    }
    &.dropdown-rtl {
      right: 0;
      direction: rtl;
    }
  }
  &.home,
  &.home .nav-small-screen-opened {
    background-color: var(--back-green);
    a.active {
      position: relative;
      // display: inline-block;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px; /* Adjust margin between text and underline */
        height: 2px; /* Adjust thickness of the underline */
        background-color: white; /* Adjust underline color */
      }
    }
    li a,
    li span,
    .app-name,
    .icon {
      color: white;
      font-weight: 500;
    }
    .drop-down-section ul {
      background-color: var(--back-green);
      color: white;
    }
  }
  &.not-home,
  &.not-home .nav-small-screen-opened {
    background-color: var(--back-golden-nav);
    a.active,
    span.active {
      color: var(--green) !important;
      font-weight: 900;
      position: relative;
      width: fit-content;

      // display: inline-block;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px; /* Adjust margin between text and underline */
        height: 2px; /* Adjust thickness of the underline */
        background-color: var(--green); /* Adjust underline color */
      }
      .icon {
        color: var(--green);
      }
    }
    li a,
    li span,
    .app-name,
    .icon {
      color: var(--black);
    }
    .drop-down-section ul {
      background-color: var(--back-golden-nav);
      color: var(--black);
    }
  }
  .resources-link,
  .apprendre-link,
  .language-link {
    position: static;
    &:hover {
      .drop-down-section {
        display: block;
      }
    }
    .has-dropdown {
      display: flex;
      align-items: center;
    }
  }
  .nav-large-screen {
    .arabic-link {
      flex-direction: row-reverse;
      .icon {
        margin: 0 7px 0px 0px;
      }
    }
  }
}
.nav-small-screen {
  display: none;
  .arabic-link {
    display: flex;
    flex-direction: row;
    .icon {
      margin: 0 7px 0px 0px;
    }
  }
  .nav-small-screen-opened {
    position: absolute;
    width: 100vw;
    left: 0;
    top: 4.9rem;
    padding: 1rem 2rem;
    ul.links-nav {
      flex-direction: column;
      gap: 0.7rem;
    }
  }
  .drop-down-section {
    position: inherit !important;
    margin: 0 2rem;
    ul {
      position: inherit !important;
      list-style: disc;
      background-color: inherit !important;
      a {
        margin: 0;
      }
    }
  }
}
ul.links-nav {
  .icon {
    font-size: 1em;
    margin: 0 0px 0px 7px;
  }
}
@media (min-width: 1101px) {
  .resources-link,
  .apprendre-link,
  .language-link {
    position: relative !important;
  }
}
@media (max-width: 1100px) {
  .nav-small-screen {
    display: block;
  }
  .nav-large-screen {
    display: none;
  }
  nav.nav-bar {
    &:not(.nav-bar-ar) {
      direction: rtl;
      padding-left: 2.2rem;
      padding-right: 1rem;
    }
    &.nav-bar-ar {
      padding-right: 2.2rem;
      padding-left: 1rem;
    }
    &:has(.arabic-nav) {
      padding-left: 1rem;
      padding-right: 2.2rem;
    }

    .nav-small-screen-opened:not(.arabic-nav) {
      direction: ltr;
    }
    .nav-small-screen-opened.arabic-nav {
      direction: rtl;
    }
  }
}
