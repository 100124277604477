.wudhu-ghousl-body {
  display: flex;
  flex-direction: column;
  padding: var(--padding-pages);
  .wudhu-description {
    text-align: justify;

    p {
      font-size: 1.2em;
      padding: 1em 2em;
    }
  }
  .wudhu-select-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5em;
    > div {
      width: 31em;
      aspect-ratio: 2 / 1;
      background: url("../../../assets/images/wudhu/icon-wudhu.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      position: relative;
      img {
        width: 7em;
        cursor: pointer;
        position: absolute;
        top: 5em;
        &.ghousl-image {
          left: 5em;
        }
        &.wudhu-image {
          right: 5em;
        }
      }
    }
  }
  .icons {
    font-size: 2em;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1000px) {
  .wudhu-ghousl-body {
    .wudhu-select-type {
      > div {
        width: 28em;

        img {
          width: 5.5em;

          &.ghousl-image {
            left: 4.5em;
          }
          &.wudhu-image {
            right: 4.5em;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .wudhu-ghousl-body {
    .wudhu-description {
      p {
        padding: 0.5em;
      }
    }
    .wudhu-select-type {
      > div {
        width: 25em;

        img {
          width: 5em;
          top: 4em;
          &.ghousl-image {
            left: 4em;
          }
          &.wudhu-image {
            right: 4em;
          }
        }
      }
    }
  }
}
