.prayer-times-header {
  background-image: url("../../assets/images/background-prayer.svg");
  height: 16em;
  background-position: center;
  background-repeat: repeat-x;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    height: 8em;
    position: absolute;
    top: 4em;
  }
}

.prayer-times-body {
  padding: var(--padding-pages);
  top: 15em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100vw;
  justify-content: space-around;

  .calendar-container {
    width: 28%;
    .type-calendar-container {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      height: 4em;
      width: 100%;
      .type-calendar {
        cursor: pointer;
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius);
        box-shadow: var(--shadow-box);
        .type {
          cursor: pointer;
          font-weight: 700;
          color: var(--blue-black);
          font-size: larger;
        }
        &.selected-type {
          background-color: var(--green);
          opacity: 0.9;
          box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15) inset;
          .type {
            color: white !important;
          }
        }
      }
    }
  }

  .prayer-times-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;

    .next-prayer {
      position: relative;
      .next-prayer-times {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        position: absolute;
        top: 21px;
        left: calc(50% - 9em);
        .time-left {
          font-size: 2.5em;
        }
      }
      img {
        height: 18em;
      }
    }
    .list-prayers {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 1em;
      .card-prayer {
        padding: 2em;
        border: 1px solid var(--back-green-2);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        box-shadow: 0px 5px 8px -2px var(--back-green-2),
          0px 0px 0px 1px var(--back-green-2);
        img {
          width: 5rem;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .prayer-times-header {
    background-size: cover;
    img {
      height: 7em;
    }
  }
  .prayer-times-body {
    align-items: center;
    .prayer-times-container {
      .list-prayers .card-prayer {
        padding: 1em 1.5em;
      }
      .next-prayer {
        img {
          height: 16em;
        }
        .next-prayer-times {
          gap: 0.3em;
          top: 10px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .prayer-times-header {
    height: 14em;
  }
  .prayer-times-body {
    flex-direction: column-reverse;
    gap: 2em;
    top: 13em;
    .prayer-times-container {
      width: 100%;
    }
    .calendar-container {
      width: auto;
      .type-calendar-container {
        height: 5em;
      }
    }
  }
}

@media (max-width: 600px) {
  .prayer-times-header {
    img {
      height: 6em;
    }
  }

  .prayer-times-body {
    .prayer-times-container {
      .next-prayer .next-prayer-times {
        font-size: 0.85em;
        left: calc(50% - 11em);

        .text-xl {
          font-size: 1.15rem;
        }
      }
      gap: 0;
      .list-prayers {
        flex-wrap: wrap;
        .card-prayer {
          flex-basis: 30%;
          box-sizing: border-box;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .prayer-times-body .prayer-times-container .next-prayer .next-prayer-times {
    font-size: 0.6em;
    left: calc(50% - 13em);
    min-width: 23em;
    .text-xl {
      font-size: 1rem;
    }
  }
}
