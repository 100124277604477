.cookiePolicy-body {
  padding: var(--padding-about-page);
  text-align: justify;
  color: var(--blue-black);
  line-height: 2em;

  ul {
    list-style: disc;
    margin: 0 3em;
  }

  h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    color: var(--green-calendar);
  }
}
