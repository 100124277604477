.details-dhikr-body {
  padding: var(--padding-pages);
  display: flex;
  justify-content: center;
  .adhkars {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 3rem;
    .details-dhikr-container {
      width: 100%;
      .content-dhikr {
        margin-bottom: 2em;
        direction: rtl;

        .details-dhikr-content {
          background: url("../../assets/images/background-dhikr.png") #f9f9f9;
          background-repeat: repeat;
          background-position: top;
          padding: 1.5rem;
          border-radius: var(--border-radius);
          text-align: justify;
          h2 {
            color: var(--blue-black);
            font-family: "Amiri Quran";
            font-size: 1.5em;
          }
        }
        .number-repeat-dhikr {
          color: var(--golden);
          font-size: 1.7em;
          font-weight: 700;
          margin: 0 1rem;
        }
      }
    }
  }
}
.icon.icon-accordion {
  font-size: 1.5em !important;
}

@media (max-width: 1000px) {
  .details-dhikr-body {
    .adhkars {
      width: 95%;
    }
  }
}
