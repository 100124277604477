.chatbot-container {
  position: fixed;
  bottom: 1em;
  right: 1%;
  width: 25em;
  height: 33em;
  border-radius: 1rem;
  background-position: center center;
  background-image: url("../../../assets/images/chatbot/bg_chatbot.png");
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border: solid 1px #40625957;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  direction: ltr;

  .chatbot-header {
    background-color: #ffffff;
    color: #101816;
    height: 12%;
    padding: 10px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border: solid 1px #40625957;
    color: var(--green);
    display: flex;
    align-items: center;
    .close-button {
      transform: translate(12px, 6px);
    }
    img {
      width: 2.5em;
      margin-right: 2em;
      max-width: 100%;
      height: auto;
    }

    span {
      width: 65%;
      font-weight: 600;
    }
  }

  .chatbot-messages {
    height: 76%;
    overflow-y: auto;
    padding: 10px;
    width: 100%;
    .loading {
      text-align: center;
    }
  }

  .splash-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 4em;
    img,
    .Lotti {
      width: 8em;
    }
    .Lotti {
      transform: translateY(-16px);
    }
  }
  .chatbot-input {
    border: solid 1px #40625957;
    display: flex;
    padding: 0.5em;
    background-color: #ffffff;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    height: 12%;

    input {
      flex: 1;
      padding: 8px;
      border-radius: 4px;
      &::placeholder {
        color: var(--green);
      }
      &:focus-visible {
        outline-color: #4062596b !important;
      }
    }
    button {
      padding: 8px;
      margin-left: 5px;
    }
  }
}
.chatbot-button {
  position: fixed;
  bottom: 0.8em;
  right: 1em;
  z-index: 1000;
  background-color: var(--chatbot-color);
  padding: 2em;
  border-radius: 28%;
  width: 5em;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/icons/chatbot-icon-black.svg");
  background-size: 65%;
}

.message {
  word-wrap: break-word;
  padding: 5%;
  padding-left: 1%;
  display: flex;

  justify-content: flex-end;
  &.user {
    .msgtTxt {
      max-width: 83%;
      background-color: var(--green);
      float: right;
      border: #f6f6f6 solid 1px;
      padding: 5%;
      color: #ffffff;
      border-radius: 20px;
    }
  }

  &.bot {
    justify-content: flex-start;
    .botAvatar {
      float: left;
      bottom: 700px;
      margin-top: 2%;
      img {
        width: 2em;
        margin-right: 1em;
      }
    }
    .msgtTxt {
      word-wrap: break-word;
      background-color: #ffffff;
      float: right;
      border: var(--green) solid 1px;
      padding: 5%;
      border-radius: 20px;
      margin-left: 1%;
    }
  }
}

@media (max-height: 500px) {
  .chatbot-container {
    height: 70%;
    display: flex;
  }
}
