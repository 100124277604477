.surah-page {
  .icon {
    cursor: pointer;
  }
  .surah-page-header {
    background-image: url("../../assets/images/backgroung-recitation.png");
    height: 12em;
    background-size: cover;
    background-position: right;
    position: relative;
    .recitation-block {
      direction: ltr;
      display: flex;
      align-items: center;
      gap: 1em;
      margin: 1em 4.5em;
      position: absolute;
      top: 3.5em;
      left: calc(50% - 34em);
      .recitation-surah {
        height: 20rem;
      }
    }
    .icon.icon-arrow-left {
      font-size: 2.5em !important;
      transform: translateX(-25px);
    }
  }
  .surah-body {
    direction: rtl;
    display: flex;
    justify-content: center;
    height: calc(100vh - 72px - 7em - 6.5rem);
    .quran-and-recitation {
      width: 70%;
      .text-quran {
        padding: var(--padding-pages);
        height: 100%;
        overflow-y: scroll;
        direction: ltr;
        line-height: 5em;

        .name-of-allah {
          text-align: center;
          color: var(--blue-black);
          font-weight: 900;
          font-size: 2em;
          margin-bottom: 0.5em;
          font-family: "Amiri";
          img {
            width: 11em;
          }
        }
        .ayah {
          font-size: 1.7em;
          color: var(--blue-black);
          margin-left: 2rem;
          text-align: justify;
          direction: rtl;
          font-family: Amiri Quran;
          .icon-ayah {
            position: relative;
            display: inline-block;
            transform: translate(0, 0.4em);
          }
          img {
            width: 1.4em;
          }
          .num-ayah {
            position: absolute;
            top: -1.7em;
            left: 0.25em !important;
            font-size: 0.55em;
            display: inline-block;
            text-align: center;
            width: 2em;
            font-weight: 600;
          }
        }
      }
    }

    .list-sorahs {
      direction: ltr;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 72px - 12em);
      overflow-y: scroll;
      width: 30%;
      align-items: center;
    }
  }
}

@media (max-width: 1300px) {
  .surah-page {
    .recitation-block {
      margin: 1em 3.5em;
    }
  }
}
@media (max-width: 1000px) {
  .surah-page {
    .surah-page-header {
      .recitation-block {
        left: calc(50% - 31em);
        margin: 1em 1.5em;
      }
    }
    .surah-body {
      flex-direction: column-reverse;
      height: fit-content;

      .list-sorahs {
        display: none;
      }
      .quran-and-recitation {
        width: 100%;
        height: calc(100vh - 72px - 7em - 5.5em);
      }
    }
  }
}
@media (max-width: 900px) {
  .surah-page {
    .surah-page-header {
      .recitation-block {
        left: calc(50% - 27.5em);
        gap: 0.5em;
      }
    }
  }
}

@media (max-width: 800px) {
  .surah-page {
    .surah-page-header {
      .recitation-block {
        flex-direction: column-reverse;
        top: 1.5em;
        left: calc(50% - 16em);
      }
      .icon.icon-arrow-left {
        display: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .surah-page {
    .surah-page-header {
      .recitation-block {
        top: 1.5em;
        left: calc(50% - 13.5em);
      }
    }
  }
}
