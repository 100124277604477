.wudhu-body {
  display: flex;
  flex-direction: column;
  padding: var(--padding-pages);
  gap: 2em;

  .step-wudhu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2em;
    height: 38vh;
    img {
      width: 23em;
    }
    .step-wudhu-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        color: var(--golden);
        font-size: 2em;
        margin-bottom: 0.5em;
        text-align: center;
      }
      p {
        font-size: 1.2em;
        padding: 1em 2em;
        text-align: justify;
      }
    }
  }
}

@media (max-width: 1000px) {
  .wudhu-body {
    .step-wudhu {
      margin: 2em 0em;
      img {
        width: 17em;
      }
    }
  }
}

@media (max-width: 600px) {
  .wudhu-body {
    .step-wudhu {
      flex-direction: column;
      height: 46vh;
      img {
        width: 14em;
      }
      .step-wudhu-details {
        h1 {
          font-size: 1.6em;
        }
      }
    }
  }
}
