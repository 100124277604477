.story-body {
  padding: 2.5em 5em;
  text-align: justify;

  h2 {
    color: var(--green);
  }

  h3 {
    color: var(--pronunciation-names);
  }

  p {
    color: var(--blue-black);
  }
}

@media (max-width: 400px) {
  .story-body {
    padding: 2.5em 3em;
  }
}
